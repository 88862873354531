<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template v-if="hasModuleAccess">
        <ValidationObserver rules="required" v-slot="{ handleSubmit }">
          <Card class="px-3 py-3 flex flex-col gap-5">
            <div class="flex flex-col justify-start items-start gap-2 my-2">
              <span class="text-darkPurple text-base font-semibold leading-5">
                Select your organisation's compensation review eligibility. The selection below will apply based on the effective date of a new compensation review.
              </span>
              <div class="flex flex-col justify-start gap-2">
                <div class="flex justify-between items-center gap-4 text-base font-semibold leading-5 text-jet">
                  <div class="flex justify-start items-center gap-2 whitespace-nowrap">
                    <input
                      type="radio" value="confirmed_employee_only" class="radio"
                      v-model="preference.compensationReviewEligibility"
                    />
                    <label for="confirmed_employee_only">Confirmed Employees Only</label>
                  </div>
                  <div class="flex justify-start items-center gap-2">
                    <div>
                      <input
                        type="radio" value="employee_with_years_service"
                        v-model="preference.compensationReviewEligibility"
                      />
                    </div>
                    <label for="employee_with_years_service">
                      Employees who have worked for a given month(s) and above
                    </label>
                  </div>
                  <div class="flex justify-start items-center gap-2 whitespace-nowrap">
                    <input
                      type="radio" value="all_employees" class="outline-0"
                      v-model="preference.compensationReviewEligibility"
                    />
                    <label for="all_employees">All Employees</label>
                  </div>
                </div>
                <template v-if="preference.compensationReviewEligibility === 'employee_with_years_service'">
                <div class="flex flex-col justify-start items-start gap-2">
                  <div class="flex justify-start items-center gap-5">
                    <span class="text-darkPurple text-base font-semibold">
                      Employees who have worked for
                    </span>
                    <TextInput
                      type="number"
                      placeholder="Enter Eligibility Period In months"
                      :value="0"
                      v-model.number="preference.numberOfServiceYear"
                    />
                    <span class="text-darkPurple text-base font-semibold">
                      month(s) and above.
                    </span>
                  </div>
                </div>
              </template>
              </div>
              <Divider border-dash class="w-full" />
            </div>
            <div class="border-b border-dashed border-romanSilver pb-5">
              <div class="flex flex-col justify-start items-start gap-2 mb-2">
                <span class="text-darkPurple text-base font-semibold leading-5">
                  Do you wish to exclude recently promoted employees in your general compensation reviews?
                </span>
                <RadioButton
                  v-model="preference.allowGrossPayToExceedRange"
                  style="width:200px"
                  space-between="mr-2 ml-5 text-darkPurple text-base font-semibold leading-5"
                  :options="[
                    { name: 'Yes', radioName: 'AllowGrossPay', value: 'true' },
                    { name: 'No', radioName: 'notAllowGrossPay', value: 'false' },
                  ]"
                />
              </div>
              <template v-if="preference.allowGrossPayToExceedRange === 'true'">
                <div class="flex flex-col justify-start items-start gap-2">
                  <div class="flex justify-start items-center gap-5">
                    <span class="text-darkPurple text-base font-semibold leading-5">
                      During mid-year or annual review, exclude employees who were promoted within the last
                    </span>
                    <TextInput
                      type="number"
                      placeholder="Enter"
                      :value="0"
                      v-model.number="preference.excludeMonth"
                    />
                    <span class="text-darkPurple text-base font-semibold leading-5">
                      month(s)
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <div class="flex flex-col justify-start items-start gap-2">
              <span class="text-darkPurple text-base font-semibold leading-5">
                Share the 'detailed compensation review report' of direct reports with functional managers for adjustment recommendations?
              </span>
              <RadioButton
                v-model="preference.shareDetailsWithManager"
                style="width:200px"
                space-between="mr-2 ml-5 text-darkPurple text-base font-semibold leading-5"
                :options="[
                  { name: 'Yes', radioName: 'shareDetails', value: 'true' },
                  { name: 'No', radioName: 'notShareDetails', value: 'false' },
                ]"
              />
            </div>
            <Divider border-dash class="w-full" />
            <div class="mt-5">
              <Button
                label="Save Changes"
                class="button-class-primary btn-bg"
                @onClick="handleSubmit(onSubmit('submitted'))"
              />
              <Button
                label="Revert Changes"
                class="button-class-secondary btn-border"
                @onClick="onSubmit('reverted')"
              />
            </div>
          </Card>
        </ValidationObserver>
      </template>
      <ErrorComponent v-else />
    </template>
  </div>
</template>

<script>
  import { ValidationObserver } from "vee-validate"
  import Loader from "@scelloo/cloudenly-ui/src/components/loader"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio"
  import TextInput from "@scelloo/cloudenly-ui/src/components/text"
  import Button from "@scelloo/cloudenly-ui/src/components/button"
  import Divider from "@/components/divider"
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    components: {
      ValidationObserver,
      Card,
      Loader,
      Button,
      RadioButton,
      TextInput,
      Divider,
      ErrorComponent
    },
    data() {
      return {
        isLoading: false,
        hasModuleAccess: false,
        preference: {
          excludeMonth: 0,
          numberOfServiceYear: '',
          shareDetailsWithManager: '',
          allowGrossPayToExceedRange: '',
          compensationReviewEligibility: '',

        }
      };
    },
    methods:{
      async getCompensationPreference(preferenceAction){
        await this.$_getCompensationPreferences().then(({ data }) => {
          const allowGrossPayToExceedRange = JSON.stringify(data.data?.allowGrossPayToExceedRange)
          const shareDetailsWithManager = JSON.stringify(data.data?.shareDetailsWithManager)
          this.preference = {...data.data, allowGrossPayToExceedRange, shareDetailsWithManager}

          if(preferenceAction === 'reverted'){
            this.$toasted.info('Preferences has been reverted', { duration: 3000 })
          }
        })
      },
      async onSubmit(setAction){
        try {
          if(setAction === 'submitted'){
            await this.$handlePrivilege('compensation', 'editPreferenceSetting')
            this.onSubmitPreference()
          } else if(setAction === 'reverted'){
            await this.$handlePrivilege('compensation', 'editPreferenceSetting')
            this.getCompensationPreference(setAction)
          }
        } catch(error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }

      },
      onSubmitPreference(){
        if(this.preference.compensationReviewEligibility === 'employee_with_years_service'){
          if(!this.preference.numberOfServiceYear){
            const message = 'Number of service years is required'
            return this.$toasted.info(message, {duration: 3000})
          }
        }
        if(!this.preference.excludeMonth) this.preference.excludeMonth = 0
        if(!this.preference.numberOfServiceYear) this.preference.numberOfServiceYear = 0

        this.isLoading = true

        const payload = {
          ...this.preference,
          orgId: this.$orgId,
        }

        return this.$_postCompensationPreferences(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      }
    },
    async created(){
      this.isLoading = true

      try {
        await this.$handlePrivilege('compensation', 'accessPreferenceSettings')
        this.hasModuleAccess = true
        await this.getCompensationPreference()
      } catch (error) {
        this.hasModuleAccess = false
      }

      this.isLoading = false
    },
  };
</script>
<style scoped>
input[type=radio] {
    appearance: none;
    background-color: #EEEEEE;
    width: 16px;
    height: 16px;
    border: 2px solid #EEEEEE;
    border-radius: 100%;
    display: inline-grid;
    place-content: center;
  }

  input[type=radio]::before {
    content: "";
    width: 10px;
    height: 10px;
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #F15A29;
    border-radius: 100%;
  }

  input[type=radio]:checked::before {
    transform: scale(1);
  }
  input[type=radio]:checked{
    background-color: #F15A29;
    border: 2px solid #EEEEEE;
    border-radius: 100%;
  }
  .button-class-primary {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
    background-color: var(--dynamic-Background) !important;
  }
  .button-class-secondary {
    color: #321C3B !important;
    background: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }

  .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
